const TRIGGER_ADF_PIPELINE: FleetOps.Permission =
  'fleetops.permissions.data_management_trigger_adf_pipeline';
const BASE_VIEWS: FleetOps.Permission =
  'fleetops.permissions.data_management_base_views';
const ADD_VALIDATION_REPORT: FleetOps.Permission =
  'fleetops.permissions.data_management_add_validation_report';
const REUSABLE_METRICS_CONFIG: FleetOps.Permission =
  'fleetops.permissions.data_management_reusable_metric_config';
const SINGLE_USE_METRICS_CONFIG: FleetOps.Permission =
  'fleetops.permissions.data_management_single_use_metric_config';
const GADGETS: FleetOps.Permission =
  'fleetops.permissions.data_management_gadgets';
const SLAS: FleetOps.Permission = 'fleetops.permissions.data_management_slas';
const UPLOAD_FILES: FleetOps.Permission = 'fleetops.permissions.upload_files';
const INTEGRATIONS_CONFIG_MODIFY: FleetOps.Permission =
  'fleetops.permissions.integrations_modify_config';
const INTEGRATIONS_CONFIG_VIEW: FleetOps.Permission =
  'fleetops.permissions.integrations_view_config';
const UPDATE_PERFORMANCE_DATASET_CONFIG: FleetOps.Permission =
  'fleetops.permissions.data_management_update_performance_dataset_config';
const DATA_MANAGER_ACCESS: FleetOps.Permission =
  'fleetops.permissions.data_management_data_manager_access';
const HIDE_DATASET_FROM_CUSTOMER: FleetOps.Permission =
  'fleetops.permissions.data_management_hide_datasets_from_customers';
const ENTITY_CONFIGURATION: FleetOps.Permission =
  'fleetops.permissions.data_management_entity_configuration';
const ACTIVITY_COSTS_VIEW: FleetOps.Permission =
  'fleetops.permissions.data_management_activity_costs_view';
const ACTIVITY_COSTS_MANAGE_DRAFT: FleetOps.Permission =
  'fleetops.permissions.data_management_activity_costs_manage_draft';
const ACTIVITY_COSTS_MANAGE_PUBLISHED: FleetOps.Permission =
  'fleetops.permissions.data_management_activity_costs_manage_published';
const ACTIVITY_COSTS_INTERVALS_EDIT: FleetOps.Permission =
  'fleetops.permissions.data_management_activity_costs_intervals_edit';
const ACTIVITY_COSTS_PUBLISH: FleetOps.Permission =
  'fleetops.permissions.data_management_activity_costs_publish';
const DELETE_DRIVERS: FleetOps.Permission =
  'fleetops.permissions.data_management_delete_driver';
const MODIFY_TARGETS: FleetOps.Permission =
  'fleetops.permissions.data_management_modify_targets';

const DATA_MANAGEMENT_PERMISSIONS = {
  TRIGGER_ADF_PIPELINE,
  BASE_VIEWS,
  ADD_VALIDATION_REPORT,
  REUSABLE_METRICS_CONFIG,
  SINGLE_USE_METRICS_CONFIG,
  DATA_MANAGER_ACCESS,
  HIDE_DATASET_FROM_CUSTOMER,
  GADGETS,
  SLAS,
  UPLOAD_FILES,
  INTEGRATIONS_CONFIG_MODIFY,
  INTEGRATIONS_CONFIG_VIEW,
  UPDATE_PERFORMANCE_DATASET_CONFIG,
  ENTITY_CONFIGURATION,
  ACTIVITY_COSTS_VIEW,
  ACTIVITY_COSTS_MANAGE_DRAFT,
  ACTIVITY_COSTS_MANAGE_PUBLISHED,
  ACTIVITY_COSTS_INTERVALS_EDIT,
  ACTIVITY_COSTS_PUBLISH,
  DELETE_DRIVERS,
  MODIFY_TARGETS,
};
Object.freeze(DATA_MANAGEMENT_PERMISSIONS);

export default DATA_MANAGEMENT_PERMISSIONS;
