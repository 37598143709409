import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import AppRoutes from '../../../navigation/appRoutes';
import TargetManager from '../../../components/TargetManager';
import useGetDatasetDefinition from '../../../hooks/useGetDatasetDefinition';

const TargetManagerScreen = () => {
  const { dataset } = useParams<{ dataset: string }>();
  const navigate = useNavigate();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const getDatasetDefinition = useGetDatasetDefinition();
  const datasetDefinition = useMemo(() => {
    if (!dataset) {
      return;
    }
    return getDatasetDefinition(dataset);
  }, [dataset, getDatasetDefinition]);

  if (dataset === undefined) {
    navigate(AppRoutes.home);
    return null;
  }

  if (!datasetDefinition) {
    navigate(AppRoutes.home);
    return null;
  }

  if (!datasetDefinition.isTargetable) {
    navigate(AppRoutes.home);
    return null;
  }

  return (
    <TargetManager
      dataset={dataset}
      hasUnsavedChanges={hasUnsavedChanges}
      setHasUnsavedChanges={setHasUnsavedChanges}
    />
  );
};

export default TargetManagerScreen;
