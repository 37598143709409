import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { TOP_BAR_BOX_SHADOW_CSS } from '../../../../components/ResourceTopBar';
import SPECIFIC_SET_ROUTES, { getValidationReportLink } from '../routes';
import ReportsContext from '../../../../contexts/ReportsContext';
import isDefined from '../../../../isDefined';
import AddValidationReportButton from './AddValidationReportButton';
import TopBarTitleRow from '../../TopBarTitleRow';
import { NabTabLinkMargin, NavTabLink } from '../../../../components/NavTab';
import Row from '../../../../components/Common/Row';
import PERMISSIONS from '../../../../permissionDefinitions';
import PermissionGates from '../../../../components/PermissionGates';
import { TOP_BAR_HEIGHT } from '../../../../constants';
import { PortalsContext } from '../../../../contextProviders/SplashScreenProviders/UserAndAccountProviders/PortalsProvider';
import useGetDatasetDefinition from '../../../../hooks/useGetDatasetDefinition';
import FleetOpsStaffOnly from '../../../../components/Common/FleetOpsStaffOnly';

const TopNavWrapper = styled.div`
  ${TOP_BAR_BOX_SHADOW_CSS};
  background-color: white;
`;

const useValidationReports = (baseView: FleetOps.BaseView) => {
  const { allReports } = useContext(ReportsContext);
  const [validationReports, setValidationReports] = useState<ReportType[]>([]);

  useEffect(() => {
    const { validationReportIds } = baseView;
    if (!validationReportIds) {
      setValidationReports([]);
      return;
    }

    setValidationReports(
      validationReportIds
        .map((id) => allReports.find((r) => r.id === id))
        .filter(isDefined),
    );
  }, [allReports, baseView]);

  return validationReports;
};

const ManageTargetsLink = ({ dataset }: { dataset: string }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const getDatasetDefinition = useGetDatasetDefinition();
  const datasetDefinition = useMemo(() => {
    return getDatasetDefinition(dataset);
  }, [dataset, getDatasetDefinition]);
  if (!datasetDefinition) {
    return null;
  }

  if (!datasetDefinition.isTargetable) {
    return null;
  }

  return (
    <NavTabLink
      title={'Manage Targets'}
      to={SPECIFIC_SET_ROUTES.buildTargetManager(dataset, selectedPortal)}
    />
  );
};

const Tabs = ({ baseView }: { baseView: FleetOps.BaseView }) => {
  const { selectedPortal } = useContext(PortalsContext);
  const validationReports = useValidationReports(baseView);

  return (
    <Row style={{ padding: '0px 24px', height: TOP_BAR_HEIGHT }}>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.DATA_MANAGEMENT.REUSABLE_METRICS_CONFIG}
      >
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Related Metrics"
            to={SPECIFIC_SET_ROUTES.buildBase(baseView.type, selectedPortal)}
            onClickEvent="Data Manager - Clicked Metrics"
          />
        </div>
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Related Compound Metrics"
            to={SPECIFIC_SET_ROUTES.buildCompoundMetrics(
              baseView.type,
              selectedPortal,
            )}
            onClickEvent="Data Manager - Clicked Compound Metrics"
          />
        </div>
      </PermissionGates.Has>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.DATA_MANAGEMENT.BASE_VIEWS}
      >
        <div style={{ marginRight: NabTabLinkMargin }}>
          <NavTabLink
            title="Fields"
            to={SPECIFIC_SET_ROUTES.buildFieldSettings(
              baseView.type,
              selectedPortal,
            )}
            onClickEvent="Data Manager - Clicked Field Settings"
          />
        </div>
      </PermissionGates.Has>
      <div style={{ marginRight: NabTabLinkMargin }}>
        <NavTabLink
          title="Raw Data"
          to={SPECIFIC_SET_ROUTES.buildSearch(baseView.type, selectedPortal)}
          onClickEvent="Data Manager - Clicked Search Raw Data"
        />
      </div>
      <div style={{ marginRight: NabTabLinkMargin }}>
        <NavTabLink
          title="Filters"
          to={SPECIFIC_SET_ROUTES.buildFilters({
            dataset: baseView.type,
            portal: selectedPortal,
          })}
          onClickEvent="Data Manager - Clicked Dataset Filters"
        />
      </div>
      <PermissionGates.Has
        requiredPermission={PERMISSIONS.DATA_MANAGEMENT.MODIFY_TARGETS}
      >
        <FleetOpsStaffOnly>
          <div style={{ marginRight: NabTabLinkMargin }}>
            <ManageTargetsLink dataset={baseView.type} />
          </div>
        </FleetOpsStaffOnly>
      </PermissionGates.Has>
      {validationReports.map((vReport) => (
        <div style={{ marginRight: NabTabLinkMargin }} key={vReport.id}>
          <NavTabLink
            title={vReport.name}
            to={getValidationReportLink({
              dataset: baseView.type,
              reportId: vReport.id,
              portal: selectedPortal,
            })}
          />
        </div>
      ))}

      <AddValidationReportButton baseView={baseView} />
    </Row>
  );
};

const TopNavigator = ({ baseView }: { baseView: FleetOps.BaseView }) => (
  <TopNavWrapper>
    <TopBarTitleRow baseView={baseView} />
    <Tabs baseView={baseView} />
  </TopNavWrapper>
);

export default TopNavigator;
